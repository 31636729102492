<template>
	<div class="confirmation--payment-success flex flex-col space-y-3 p-4 rounded-2xl">
		<div class="flex justify-center space-x-2 items-center">
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect width="32" height="32" rx="16" fill="#27AE60"/>
				<path d="M8 16.2222L13.1831 21.3333L24 10.6666" stroke="white" stroke-width="2.66667"/>
			</svg>
			<h2 class="text-white">{{ $t('confirmation.success.title') }}</h2>
		</div>

		<div v-if="machine" class="flex justify-center">
			<template v-if="!startedInCurrentWindow">
				<RouterLink v-if="machine" :to="{ name: machine.type === 'fridge' ? 'OrderFridge' :  'OrderJunea' }">
					<button class="text-base text-white font-medium py-2 px-6 border-2 border-white rounded-full">
						{{ $t('confirmation.success.button') }}
					</button>
				</RouterLink>

				<RouterLink v-else :to="{ name: 'HomeScreen' }">
					<button class="text-base text-white font-medium py-2 px-6 border-2 border-white rounded-full">
						{{ $t('confirmation.success.button-home') }}
					</button>
				</RouterLink>
			</template>

			<!-- Return to app -->
			<p v-else class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">
				{{ $t('order.order-overview.return-to-app') }}
			</p>
		</div>
	</div>
</template>

<script>
import useMachine from '@/composables/useMachine';

export default {
	props: {
		startedInCurrentWindow: Boolean,
	},

	setup() {
		const { machine } = useMachine();

		return {
			machine,
		};
	}
}
</script>

<style lang="scss" scoped>
.confirmation--payment-success {
	background: linear-gradient(0deg, rgba(39, 174, 96, 0.16), rgba(39, 174, 96, 0.16)), #2E2E2E;
}
</style>
